import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface StoreInfo {
  isLoading?: boolean;
}

@Module
export default class GlobalSettingsModule
  extends VuexModule
  implements StoreInfo
{
  isLoading = false;

  get getIsLoading(): boolean {
    return this.isLoading;
  }

  @Mutation
  ["STARTLOADING"]() {
    this.isLoading = true;
  }

  @Mutation
  ["STOPLOADING"]() {
    this.isLoading = false;
  }
}
