import ApiService from "@/core/services/ApiService";
import { BaseDictionary } from "@/modules/dictionaries/baseDictionary.model";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class EnumsModule extends VuexModule {
  claimStatuses = [] as BaseDictionary[];
  encounterStatuses = [] as BaseDictionary[];
  patientRelationshipToSubscriber = [] as BaseDictionary[];
  encounterActions = [] as BaseDictionary[];
  claimActions = [] as BaseDictionary[];
  patientActions = [] as BaseDictionary[];
  patientPayerPriority = [] as BaseDictionary[];
  patientSex = [] as BaseDictionary[];
  encounterTypes = [] as BaseDictionary[];
  paymentTypes = [] as BaseDictionary[];
  transactionTypes = [] as BaseDictionary[];
  adjustmentReasonCodes = [] as BaseDictionary[];
  adjustmentSigns = [] as BaseDictionary[];
  cardTypes = [] as BaseDictionary[];
  noteStatuses = [] as BaseDictionary[];
  noteTypes = [] as BaseDictionary[];
  paymentPlanCollectionTypes = [] as BaseDictionary[];
  installmetStatuses = [] as BaseDictionary[];
  nexioStatuses = [] as BaseDictionary[];

  get allInstallmetStatuses(): BaseDictionary[] {
    return this.installmetStatuses;
  }

  get allPaymentPlanCollectionTypes(): BaseDictionary[] {
    return this.paymentPlanCollectionTypes;
  }

  get allClaimStatuses(): BaseDictionary[] {
    return this.claimStatuses;
  }

  get allEncounterStatuses(): BaseDictionary[] {
    return this.encounterStatuses;
  }

  get allEncounterActions(): BaseDictionary[] {
    return this.encounterActions;
  }

  get allClaimActions(): BaseDictionary[] {
    return this.claimActions;
  }

  get allPatientActions(): BaseDictionary[] {
    return this.patientActions;
  }

  get allPatientRelationshipToSubscriber(): BaseDictionary[] {
    return this.patientRelationshipToSubscriber;
  }

  get allPatientPayerPriority(): BaseDictionary[] {
    return this.patientPayerPriority;
  }

  get allPatientSex(): BaseDictionary[] {
    return this.patientSex;
  }

  get allEncounterTypes(): BaseDictionary[] {
    return this.encounterTypes;
  }

  get allPaymentTypes(): BaseDictionary[] {
    return this.paymentTypes;
  }

  get allTransactionTypes(): BaseDictionary[] {
    return this.transactionTypes;
  }

  get allAdjustmentReasonCodes(): BaseDictionary[] {
    return this.adjustmentReasonCodes;
  }

  get allCardTypes(): BaseDictionary[] {
    return this.cardTypes;
  }

  get allAdjustmentSigns(): BaseDictionary[] {
    return this.adjustmentSigns;
  }

  get allNoteStatuses(): BaseDictionary[] {
    return this.noteStatuses;
  }

  get allNoteTypes(): BaseDictionary[] {
    return this.noteTypes;
  }

  get allNexioStatuses(): BaseDictionary[] {
    return this.nexioStatuses;
  }

  @Mutation
  ["SETALLENUMS"](data) {
    this.claimStatuses = data.claimStatuses;
    this.encounterStatuses = data.encounterStatuses;
    this.patientRelationshipToSubscriber = data.patientRelationshipToSubscriber;
    this.encounterActions = data.encounterActions;
    this.claimActions = data.claimActions;
    this.patientActions = data.patientActions;
    this.patientPayerPriority = data.patientPayerPriority;
    this.patientSex = data.patientSex;
    this.encounterTypes = data.encounterTypes;
    this.paymentTypes = data.paymentTypes;
    this.transactionTypes = data.transactionTypes;
    this.adjustmentReasonCodes = data.adjustmentReasonCodes;
    this.cardTypes = data.cardTypes;
    this.adjustmentSigns = data.adjustmentSigns;
    this.noteStatuses = data.noteStatuses;
    this.noteTypes = data.noteTypes;
    this.paymentPlanCollectionTypes = data.paymentPlanCollectionTypes;
    this.installmetStatuses = data.installmetStatuses;
    this.nexioStatuses = data.nexioStatuses;
  }

  @Action
  ["GETALLENUMS"]() {
    return ApiService.post("api/v1/modelDictionaries/enumList", {}).then(
      (res) => {
        this.context.commit("SETALLENUMS", res.data);
      }
    );
  }
}
